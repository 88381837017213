<style>
.vue__time-picker-dropdown ul li:not([disabled]).active,
.vue__time-picker-dropdown ul li:not([disabled]).active:focus,
.vue__time-picker-dropdown ul li:not([disabled]).active:hover,
.vue__time-picker .dropdown ul li:not([disabled]).active,
.vue__time-picker .dropdown ul li:not([disabled]).active:focus,
.vue__time-picker .dropdown ul li:not([disabled]).active:hover {
  background: #7253cf;
  color: #fff;
}

.vue__time-picker input.display-time {
  border: 1px solid #0000005e;
  width: 22.5em;
  height: 2.5em;
  padding: 0.3em 0.5em;
  font-size: 1em;
  border-radius: 5px;
  font-weight: bold;
}
</style>
<template>
  <v-row justify="center">
    <v-dialog
      :fullscreen="$vuetify.breakpoint.xsOnly"
      v-model="show"
      max-width="800px"
      max-height="800px"
      scrollable
      persistent
    >
      <v-card background-color="#f2f2f2">
        <v-card-title style="background-color: #7253cf; color: white">
          <div class="d-flex px-1">
            <img
              src="@/assets/thingsToDo/harlemlogo01.png"
              alt=""
              size="100%"
              class="logo"
            />
          </div>
          <div class="ml-7 mb-3 dialogTitle-text">
            {{ dialogTitle }}
          </div>
          <v-spacer></v-spacer>
          <v-icon
            text
            size="32"
            color="white"
            @click="toggleAddEditViewGameModal({ show: false })"
            style="cursor: pointer; margin-top: -70px; margin-right: -12px"
            >mdi-close
          </v-icon>
        </v-card-title>
        <v-card-text v-if="loading">
          <div class="text-center" style="min-height: 500px">
            <v-progress-circular
              :size="100"
              :width="10"
              style="
                text-align: center;
                padding-top: 20px;
                padding-bottom: 20px;
                margin-top: 200px;
              "
              color="#7253CF"
              indeterminate
            ></v-progress-circular>
          </div>
        </v-card-text>
        <v-card-text class="pt-5" v-else>
          <v-form lazy-validation ref="gameForm">
            <v-row no-gutters>
              <p style="font-size: 19px">Contract Type</p>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12">
                <v-radio-group
                  class="py-0"
                  style="margin-top: 0px"
                  small
                  v-model="contractType"
                  row
                >
                  <v-radio
                    :disabled="formLoading"
                    color="#2C1963"
                    label="Low Risk"
                    value="Low Risk"
                  ></v-radio>
                  <v-radio
                    :disabled="formLoading"
                    color="#2C1963"
                    label="Reduce Risk"
                    value="RRisk"
                  ></v-radio>
                  <v-radio
                    :disabled="formLoading"
                    color="#2C1963"
                    label="Minimum Gaurantee"
                    value="Min Guar"
                  ></v-radio>
                  <v-radio
                    :disabled="formLoading"
                    color="#2C1963"
                    class="py-2"
                    label="Flat Fees"
                    value="Flat"
                  ></v-radio>
                  <v-radio
                    :disabled="formLoading"
                    color="#2C1963"
                    label="Throwback"
                    value="Throwback"
                  ></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
            <v-row no-gutters
              ><v-col cols="12">
                <v-text-field
                  outlined
                  dense
                  v-model="contract_status"
                  label="Contract Status"
                  color="#7253CF"
                  class="formFields"
                  :disabled="formLoading"
                >
                </v-text-field></v-col
            ></v-row>
            <v-row no-gutters
              ><v-col cols="12">
                <v-autocomplete
                  label="Game Type"
                  outlined
                  dense
                  v-model="game_type"
                  :items="gameTypeOptions"
                  class="formFields"
                  color="#7253CF"
                  :disabled="formLoading"
                ></v-autocomplete> </v-col
            ></v-row>
            <v-row no-gutters>
              <p style="font-size: 19px">Timezone</p>
            </v-row>

            <v-row no-gutters
              ><v-col cols="12">
                <v-autocomplete
                  label="Timezone"
                  outlined
                  dense
                  v-model="game_timezone"
                  :items="timezoneOptions"
                  :rules="[rules.required]"
                  class="formFields"
                  color="#7253CF"
                  :disabled="formLoading"
                ></v-autocomplete> </v-col
            ></v-row>

            <v-row no-gutters>
              <v-col
                cols="6"
                class="pr-2"
                v-if="contractType === 'RRisk' || contractType === 'Low Risk'"
              >
                <v-text-field
                  outlined
                  dense
                  v-model="Fees"
                  label="Advance Payment"
                  color="#7253CF"
                  class="formFields"
                  type="number"
                  :disabled="formLoading"
                >
                  <template v-slot:prepend-inner>
                    <v-icon color="#067605">mdi-currency-usd</v-icon>
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="3" class="pr-2" v-if="contractType === 'Min Guar'">
                <v-text-field
                  outlined
                  dense
                  v-model="Fees"
                  label="Minimum Fees"
                  color="#7253CF"
                  class="formFields"
                  type="number"
                  :disabled="formLoading"
                >
                  <template v-slot:prepend-inner>
                    <v-icon color="#067605">mdi-currency-usd</v-icon>
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="3" class="pr-2" v-if="contractType === 'Flat'">
                <v-text-field
                  outlined
                  dense
                  v-model="Fees"
                  label="Flat Fees"
                  color="#7253CF"
                  class="formFields"
                  type="number"
                  :disabled="formLoading"
                >
                  <template v-slot:prepend-inner>
                    <v-icon color="#067605">mdi-currency-usd</v-icon>
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="3" class="pr-2" v-if="contractType !== 'Flat'">
                <v-text-field
                  outlined
                  dense
                  v-model="ticketRevenue"
                  label="Ticket Revenue"
                  color="#7253CF"
                  class="formFields"
                  type="number"
                  :disabled="formLoading"
                >
                  <template v-slot:prepend-inner>
                    <v-icon color="#38227A">mdi-percent-outline</v-icon>
                  </template>
                </v-text-field>
              </v-col>
              <v-col
                cols="3"
                :class="contractType === 'Flat' ? 'pl-2' : 'pr-2'"
              >
                <v-text-field
                  outlined
                  dense
                  v-model="paymentDeposit"
                  label="Payment Deposit"
                  color="#7253CF"
                  class="formFields"
                  type="number"
                  :disabled="formLoading"
                >
                  <template v-slot:prepend-inner>
                    <v-icon color="#067605">mdi-currency-usd</v-icon>
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="3" class="pr-2">
                <v-text-field
                  outlined
                  dense
                  v-model="travel_fees"
                  label="Travel Fees"
                  color="#7253CF"
                  class="formFields"
                  type="number"
                  :disabled="formLoading"
                >
                  <template v-slot:prepend-inner>
                    <v-icon color="#067605">mdi-currency-usd</v-icon>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
            <v-container class="pa-4" style="margin-left: -14px; width: 106%">
              <v-card outlined class="pa-4">
                <v-row>
                  <p style="font-size: 19px">Co-Chair Details</p>
                </v-row>
                <v-row>
                  <v-col cols="12" class="pr-2">
                    <v-text-field
                      outlined
                      dense
                      v-model="co_chair_name"
                      label="Co-Chair Name"
                      color="#7253CF"
                      class="formFields"
                      :disabled="formLoading"
                    />
                  </v-col>
                  <v-col cols="6" class="pr-2">
                    <v-text-field
                      outlined
                      dense
                      v-model="co_chair_phone"
                      label="Co-Chair Phone"
                      color="#7253CF"
                      class="formFields"
                      :disabled="formLoading"
                    />
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      outlined
                      dense
                      v-model="co_chair_email"
                      label="Co-Chair Email"
                      color="#7253CF"
                      class="formFields"
                      :disabled="formLoading"
                    />
                  </v-col>
                </v-row>
              </v-card>
            </v-container>
            <br />
            <v-row no-gutters>
              <v-col cols="6" class="pr-2">
                <v-text-field
                  outlined
                  dense
                  v-model="first_name"
                  label="First Name"
                  color="#7253CF"
                  class="formFields"
                  :rules="[rules.required]"
                  :disabled="formLoading"
                >
                </v-text-field>
              </v-col>
              <v-col cols="6" class="pr-2">
                <v-text-field
                  outlined
                  dense
                  v-model="last_name"
                  label="Last Name"
                  color="#7253CF"
                  class="formFields"
                  :disabled="formLoading"
                  :rules="[rules.required]"
                >
                </v-text-field>
              </v-col>
              <v-col cols="6" class="pr-2">
                <v-text-field
                  outlined
                  dense
                  v-model="mobile"
                  label="Mobile Number"
                  color="#7253CF"
                  class="formFields"
                  :disabled="formLoading"
                >
                </v-text-field>
              </v-col>
              <v-col cols="6" class="pr-2">
                <v-text-field
                  outlined
                  dense
                  v-model="email"
                  @keyup="getSchoolEmailList"
                  label="Contact Person Email"
                  color="#7253CF"
                  class="formFields"
                  :disabled="formLoading"
                >
                </v-text-field>
                <input
                  type="hidden"
                  v-model="hiddenValue1"
                  name="hiddenValue1"
                />
                <input
                  type="hidden"
                  v-model="hiddenValue2"
                  name="hiddenValue2"
                />
                <ul>
                  <li v-for="emailList in emailList" :key="emailList.id">
                    <button
                      @click="
                        selectEmail(
                          emailList.username,
                          emailList.user_type,
                          emailList.sub_user_type,
                          $event
                        )
                      "
                    >
                      {{ emailList.username }}
                    </button>
                  </li>
                </ul>

                <!--  <input
                  v-model="email"
                  @input="getSchoolEmailList"
                  placeholder="Enter email address"
                />-->
              </v-col>

              <v-col cols="6" class="pr-2">
                <v-text-field
                  outlined
                  dense
                  v-model="organization_name"
                  label="Organization Name"
                  color="#7253CF"
                  class="formFields"
                  :disabled="formLoading"
                  :rules="[rules.required]"
                >
                </v-text-field>
              </v-col>
              <v-col cols="6" class="pr-2">
                <v-text-field
                  outlined
                  dense
                  v-model="title"
                  label="Title"
                  color="#7253CF"
                  class="formFields"
                  :disabled="formLoading"
                >
                </v-text-field>
              </v-col>
              <v-col cols="6" class="pr-2">
                <v-autocomplete
                  label="Event Support"
                  outlined
                  dense
                  v-model="event_support"
                  :items="eventSupportList"
                  class="formFields"
                  item-text="name"
                  item-value="id"
                  color="#7253CF"
                  :disabled="formLoading"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="6" class="pr-2">
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      outlined
                      dense
                      v-model="formatedDate"
                      prepend-inner-icon="mdi-calendar-range"
                      label="Game Date"
                      :rules="[rules.required]"
                      v-bind="attrs"
                      readonly
                      color="#7253CF"
                      class="formFields"
                      :disabled="formLoading"
                      v-on="on"
                    >
                    </v-text-field>
                  </template>
                  <v-date-picker
                    v-model="gameDate"
                    no-title
                    @input="menu = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="6" class="pr-2">
                <label style="font-size: 16px; font-weight: 500"
                  >Game Start Time</label
                >
                <br />
                <vue-timepicker
                  format="hh:mm A"
                  class="formFields"
                  v-model="start_time"
                  placeholder="Game Start Time"
                  @change="
                    autocalculatdoorsopenattime(), autocalculateendtime()
                  "
                  close-on-complete
                  input-width="100%"
                ></vue-timepicker>
              </v-col>
              <v-col cols="6" class="pr-2">
                <label style="font-size: 16px; font-weight: 500"
                  >Game End Time</label
                >
                <br />
                <vue-timepicker
                  format="hh:mm A"
                  class="formFields"
                  v-model="end_time"
                  placeholder="Game End Time"
                  close-on-complete
                  input-width="100%"
                ></vue-timepicker>
              </v-col>
            </v-row>
            <br />
            <v-row no-gutters>
              <v-col cols="6" class="pr-2">
                <label style="font-size: 16px; font-weight: 500"
                  >Doors Open At</label
                >
                <br />
                <vue-timepicker
                  format="hh:mm A"
                  class="formFields"
                  v-model="admission_time"
                  placeholder="Doors Open At"
                  close-on-complete
                  input-width="100%"
                ></vue-timepicker>
              </v-col>
              <v-col cols="6" class="pr-2">
                <label style="font-size: 16px; font-weight: 500"></label>
                <br />
                <v-autocomplete
                  label="Sales Rep"
                  outlined
                  dense
                  v-model="salesRep"
                  :items="salesRepList"
                  class="formFields"
                  item-text="name"
                  item-value="id"
                  color="#7253CF"
                  :disabled="formLoading"
                >
                </v-autocomplete>
              </v-col>

              <v-col cols="6" class="pr-2">
                <v-autocomplete
                  label="Team"
                  outlined
                  dense
                  v-model="team"
                  :items="teamNameList"
                  class="formFields"
                  item-text="team_name"
                  item-value="id"
                  color="#7253CF"
                  :disabled="formLoading"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="6" v-if="this.team == null">
                <v-combobox
                  v-model="players"
                  :items="playerList"
                  label="Player"
                  class="formFields"
                  item-text="name"
                  item-value="id"
                  color="#7253CF"
                  :disabled="formLoading"
                  multiple
                  outlined
                  dense
                ></v-combobox>
              </v-col>
              <v-col cols="6" class="pr-2">
                <v-text-field
                  label="Name of Event Location"
                  outlined
                  dense
                  v-model="nameOfEventLocation"
                  class="formFields"
                  item-text="school_name"
                  item-value="id"
                  color="#7253CF"
                  :disabled="formLoading"
                >
                </v-text-field>
              </v-col>
              <v-col cols="6" class="pr-2">
                <v-text-field
                  outlined
                  dense
                  v-model="eventAddress"
                  label="Address Of Event Location"
                  color="#7253CF"
                  class="formFields"
                  :disabled="formLoading"
                >
                </v-text-field>
              </v-col>
              <v-col cols="6" class="pr-2">
                <v-text-field
                  label="Zip Code"
                  outlined
                  dense
                  v-model="pinCode"
                  class="formFields"
                  color="#7253CF"
                  :disabled="formLoading"
                  :readonly="type === 'view'"
                >
                </v-text-field>
              </v-col>
              <v-col cols="6" class="pr-2">
                <v-autocomplete
                  label="Event State"
                  outlined
                  dense
                  v-model="state"
                  :items="statesList"
                  class="formFields"
                  item-text="state_name"
                  item-value="id"
                  color="#7253CF"
                  :disabled="formLoading"
                  :readonly="type === 'view'"
                  :rules="[rules.required]"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="6" class="pr-2">
                <v-text-field
                  label="Event City"
                  outlined
                  dense
                  v-model="city"
                  class="formFields"
                  color="#7253CF"
                  :disabled="formLoading"
                  :readonly="type === 'view'"
                  :rules="[rules.required]"
                >
                </v-text-field>
              </v-col>

              <v-col cols="6" class="pr-2">
                <v-menu
                  ref="menu2"
                  v-model="menu2"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      outlined
                      dense
                      v-model="formattedContractDueDate"
                      prepend-inner-icon="mdi-calendar-range"
                      label="Date Contract Due"
                      v-bind="attrs"
                      readonly
                      color="#7253CF"
                      class="formFields"
                      :disabled="formLoading"
                      v-on="on"
                    >
                    </v-text-field>
                  </template>
                  <v-date-picker
                    v-model="contractDueDate"
                    no-title
                    @input="menu2 = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="6" class="pr-2">
                <v-menu
                  ref="menu3"
                  v-model="menu3"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      outlined
                      dense
                      v-model="formattedDepositDueDate"
                      prepend-inner-icon="mdi-calendar-range"
                      label="Date Deposit Due"
                      v-bind="attrs"
                      readonly
                      color="#7253CF"
                      class="formFields"
                      :disabled="formLoading"
                      v-on="on"
                    >
                    </v-text-field>
                  </template>
                  <v-date-picker
                    v-model="depositDueDate"
                    no-title
                    @input="menu3 = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="6" class="pr-2">
                <v-combobox
                  v-model="ticketType"
                  :items="ticketTypeList"
                  label="Select Ticket Types"
                  class="formFields"
                  item-text="name"
                  item-value="id"
                  color="#7253CF"
                  :disabled="formLoading"
                  multiple
                  outlined
                  dense
                ></v-combobox>
              </v-col>
              <v-col cols="12" v-if="this.create_on_pretix == false">
                <v-checkbox
                  color="#7253CF"
                  class="formFields"
                  label="Create On Pretix"
                  v-model="create_on_pretix"
                  true-value="true"
                  false-value="false"
                ></v-checkbox>
              </v-col>

              <!-- <v-col cols="6" class="pr-2">
                <v-text-field
                  outlined
                  dense
                  v-model="numberOfStudentDistrict"
                  label="No. Of Student In District"
                  color="#7253CF"
                  class="formFields"
                  :rules="[rules.required]"
                  type="number"
                  :disabled="formLoading"
                >
                </v-text-field>
              </v-col>
              <v-col cols="6" class="pl-2">
                <v-autocomplete
                  label="Team Assigned"
                  outlined
                  dense
                  :rules="[rules.required]"
                  v-model="assignedTeam"
                  :items="assignedTeamList"
                  class="formFields"
                  item-text="name"
                  item-value="id"
                  color="#7253CF"
                  :disabled="formLoading"
                >
                </v-autocomplete>
              </v-col>

              <v-col cols="12">
                <v-text-field
                  outlined
                  dense
                  v-model="event_support"
                  label="Event Support"
                  color="#7253CF"
                  class="formFields"
                  :disabled="formLoading"
                  :rules="[rules.required]"
                >
                </v-text-field>
               
              </v-col> -->
              <!-- <v-col cols="6" class="pr-2">
                <v-text-field
                  outlined
                  dense
                  v-model="bestCaseGoal"
                  label="Best Case Goal"
                  color="#7253CF"
                  class="formFields"
                  type="number"
                  :disabled="formLoading"
                  :rules="[rules.required]"
                >
                  <template v-slot:prepend-inner>
                    <v-icon color="#067605">mdi-currency-usd</v-icon>
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="6" class="pl-2">
                <v-text-field
                  outlined
                  dense
                  v-model="worstCaseGoal"
                  label="Worst Case Goal"
                  color="#7253CF"
                  class="formFields"
                  type="number"
                  :disabled="formLoading"
                  :rules="[rules.required]"
                >
                  <template v-slot:prepend-inner>
                    <v-icon color="#067605">mdi-currency-usd</v-icon>
                  </template>
                </v-text-field>
              </v-col> -->

              <!-- <v-col cols="12">
                <v-text-field
                  outlined
                  dense
                  v-model="shippingAddress"
                  label="Shipping Address"
                  color="#7253CF"
                  class="formFields"
                  :disabled="formLoading"
                  :rules="[rules.required]"
                >
                </v-text-field>
              </v-col> -->
            </v-row>

            <v-row no-gutters v-if="this.type == 'edit'">
              <v-col cols="12">
                <p style="font-size: 19px">1st Event? Repeat?</p>
              </v-col>
              <v-col cols="12">
                <div class="d-flex">
                  <v-radio-group
                    class="py-0"
                    style="margin-top: 0px"
                    small
                    v-model="selectedEventRadio"
                  >
                    <v-radio
                      :disabled="formLoading"
                      v-for="(item, i) in eventRadio"
                      :key="i"
                      color="#2C1963"
                      :label="item.label"
                      :value="item.value"
                    ></v-radio>
                  </v-radio-group>
                </div>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  outlined
                  dense
                  v-model="no_of_ticket_if_repeat"
                  label="If REPEAT # Of Tickets Sold"
                  color="#7253CF"
                  class="formFields"
                  type="number"
                  :disabled="formLoading"
                >
                </v-text-field>
              </v-col>
              <v-col cols="6" class="pr-2">
                <v-text-field
                  outlined
                  dense
                  v-model="total_gate"
                  label="Total Gate"
                  color="#7253CF"
                  class="formFields"
                  type="number"
                  :disabled="formLoading"
                >
                </v-text-field>
              </v-col>
              <v-col cols="6" class="pl-2">
                <v-autocomplete
                  outlined
                  dense
                  v-model="is_wizfit"
                  :items="questions"
                  label="WizFit"
                  color="#7253CF"
                  class="formFields"
                  :disabled="formLoading"
                >
                </v-autocomplete>
              </v-col>
              <!--  <v-col cols="12">
                <v-textarea
                  outlined
                  dense
                  v-model="event_overview"
                  label="Event Overview"
                  color="#7253CF"
                  class="formFields"
                  :disabled="formLoading"
                  :row-height="2"
                ></v-textarea>
              </v-col>-->
              <!-- <v-col cols="6" class="pr-2">
                <v-text-field
                  outlined
                  dense
                  v-model="fundraising_goal"
                  label="Fundraising Goal"
                  color="#7253CF"
                  class="formFields"
                  type="number"
                  :disabled="formLoading"
                >
                  <template v-slot:prepend-inner>
                    <v-icon color="#067605">mdi-currency-usd</v-icon>
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="6" class="pl-2">
                <v-text-field
                  outlined
                  dense
                  v-model="go_for_goal"
                  label="Minimum Goal"
                  color="#7253CF"
                  class="formFields"
                  type="number"
                  :disabled="formLoading"
                >
                  <template v-slot:prepend-inner>
                    <v-icon color="#067605">mdi-currency-usd</v-icon>
                  </template>
                </v-text-field>
              </v-col>-->
              <v-col cols="12">
                <v-text-field
                  outlined
                  dense
                  v-model="seating_capacity"
                  label="Seating Capacity"
                  color="#7253CF"
                  class="formFields"
                  type="number"
                  :disabled="formLoading"
                >
                </v-text-field>
              </v-col>
              <v-col cols="6" class="pr-2">
                <v-text-field
                  outlined
                  dense
                  v-model="no_k8_students_marketing_to"
                  label="#Of K-8 Students Marketing To"
                  color="#7253CF"
                  class="formFields"
                  type="number"
                  :disabled="formLoading"
                >
                </v-text-field>
              </v-col>
              <v-col cols="6" class="pl-2">
                <v-text-field
                  outlined
                  dense
                  v-model="no_k8_students_in_district"
                  label="#Of K-8 Students In District"
                  color="#7253CF"
                  class="formFields"
                  type="number"
                  :disabled="formLoading"
                >
                </v-text-field>
              </v-col>
              <v-col cols="6" class="pr-2">
                <v-text-field
                  outlined
                  dense
                  v-model="no_of_elementary_school"
                  label="# Of Elementary Schools"
                  color="#7253CF"
                  class="formFields"
                  type="number"
                  :disabled="formLoading"
                >
                </v-text-field>
              </v-col>
              <v-col cols="6" class="pl-2">
                <v-text-field
                  outlined
                  dense
                  v-model="no_of_middle_school"
                  label="# Of Middle Schools"
                  color="#7253CF"
                  class="formFields"
                  type="number"
                  :disabled="formLoading"
                >
                </v-text-field>
              </v-col>

              <v-col cols="12">
                <v-text-field
                  outlined
                  dense
                  v-model="ticket_increased_from_last_game"
                  label="Ticket Increase From Last Game?"
                  placeholder="Yes or No- If YES explain"
                  color="#7253CF"
                  class="formFields"
                  :disabled="formLoading"
                >
                </v-text-field>
              </v-col>
              <!-- <v-col cols="12">
                <p style="font-size: 19px">Marketing Plan</p>
              </v-col>
              <v-col cols="6" class="pr-2">
                <v-autocomplete
                  outlined
                  dense
                  v-model="virtual_assembly"
                  :items="questions"
                  label="Virtual Assembly"
                  color="#7253CF"
                  class="formFields"
                  :disabled="formLoading"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="6" class="pl-2">
                <v-autocomplete
                  outlined
                  dense
                  v-model="in_person_assembly"
                  :items="questions"
                  label="In Person Assembly"
                  color="#7253CF"
                  class="formFields"
                  :disabled="formLoading"
                >
                </v-autocomplete>
              </v-col>-->
              <v-col cols="12">
                <v-autocomplete
                  outlined
                  dense
                  v-model="ticket_incentive_program"
                  :items="questions"
                  label="Ticket Incentive Program"
                  color="#7253CF"
                  class="formFields"
                  :disabled="formLoading"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  outlined
                  dense
                  v-model="ticket_incentive_detail"
                  label="Ticket Incentive Details"
                  color="#7253CF"
                  class="formFields"
                  :disabled="formLoading"
                  :row-height="2"
                ></v-textarea>
              </v-col>
              <v-col cols="12">
                <label>NOTES</label>
                <quillEditor v-model="notes" :options="editorOption" />
              </v-col>
            </v-row>
            <br />
            <br />
            <v-row>
              <v-col
                cols="12"
                sm="6"
                md="6"
                v-if="this.type == 'edit' && this.pretixslug"
                style="margin-top: -50px"
              >
                <v-radio-group
                  class="radio-group"
                  small
                  v-model="pretix_game_mode"
                  row
                >
                  <v-radio
                    :disabled="formLoading"
                    ref="editButton"
                    color="#2C1963"
                    label="Pretix Testmode"
                    value="test_mode"
                  ></v-radio>
                  <v-radio
                    :disabled="formLoading"
                    color="#2C1963"
                    label="Pretix Livemode"
                    value="live_mode"
                  ></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="6"
                class="pr-2"
                v-if="this.type == 'edit'"
                style="margin-top: -19px"
              >
                <v-text-field
                  outlined
                  dense
                  v-model="pretix_tax_percentage"
                  label="Pretix Tax Percentage"
                  color="#7253CF"
                  class="formFields"
                  type="number"
                  :disabled="formLoading"
                >
                </v-text-field>
              </v-col>
              <v-col
                cols="3"
                class="pl-2"
                v-if="this.type == 'edit' && this.pretixslug"
                style="margin-top: -19px"
              >
                <v-menu
                  ref="menu5"
                  v-model="menu5"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      outlined
                      dense
                      v-model="formattedPresaleDate"
                      prepend-inner-icon="mdi-calendar-range"
                      label="Presale Start Date"
                      v-bind="attrs"
                      readonly
                      color="#7253CF"
                      class="formFields"
                      :disabled="formLoading"
                      v-on="on"
                    >
                    </v-text-field>
                  </template>
                  <v-date-picker
                    v-model="presale_startdate"
                    no-title
                    @input="menu5 = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col
                cols="3"
                class="pl-2"
                v-if="this.type == 'edit' && this.pretixslug"
                style="margin-top: -19px"
              >
                <v-autocomplete
                  label="Presale Status"
                  outlined
                  dense
                  v-model="presale_status"
                  :items="presaleStatusOptions"
                  class="formFields"
                  color="#7253CF"
                  :disabled="formLoading"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="6"
                class="pr-2"
                v-if="this.type == 'edit' && this.pretixslug"
              >
                <vue-timepicker
                  format="hh:mm A"
                  class="formFields"
                  v-model="presale_starttime"
                  placeholder="Presale Start Time"
                  close-on-complete
                ></vue-timepicker>
              </v-col>
            </v-row>

            <v-row v-if="this.type == 'edit' && this.pretixslug">
              <v-col cols="12" style="margin-top: -29px">
                <p style="font-size: 19px; margin-top: 30px">Ticket Quota</p>
              </v-col>
              <v-col
                cols="6"
                v-for="(item, i) in ticketquota"
                :key="i"
                :class="i % 2 === 0 ? 'pr-2' : 'pl-2'"
                style="margin-top: -18px"
              >
                <v-text-field
                  :label="item.quota_name"
                  outlined
                  dense
                  v-model="item.size"
                  class="formFields"
                  item-text="school_name"
                  item-value="id"
                  color="#7253CF"
                  :disabled="formLoading"
                >
                </v-text-field>
              </v-col>
            </v-row>

            <v-row v-if="this.type == 'edit'" style="margin-top: -29px">
              <v-col cols="12">
                <p style="font-size: 19px">Ticket Price</p>
              </v-col>
              <v-col
                cols="6"
                v-for="(item, i) in ticketprice"
                :key="i"
                :class="i % 2 === 0 ? 'pr-2' : 'pl-2'"
                style="margin-top: -18px"
              >
                <v-row>
                  <v-col cols="2">
                    <v-checkbox
                      color="#7253CF"
                      v-model="item.active"
                      style="margin-top: 5px"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="8">
                    <v-text-field
                      :label="item.item_name"
                      outlined
                      dense
                      v-model="item.default_price"
                      class="formFields"
                      item-text="school_name"
                      item-value="id"
                      color="#7253CF"
                      :disabled="formLoading"
                    >
                    </v-text-field>
                  </v-col>
                  <!-- <v-col cols="4">
                    <v-text-field
                      label="door price"
                      outlined
                      dense
                      v-model="item.door_price"
                      class="formFields"
                      item-value="id"
                      color="#7253CF"
                      :disabled="formLoading"
                    >
                    </v-text-field>
                  </v-col>-->
                </v-row>
              </v-col>
            </v-row>
            <v-row v-if="this.type == 'edit' && this.pretixslug">
              <v-textarea
                outlined
                dense
                v-model="frontpage_text"
                :rules="[rules.required]"
                label="Frontpage Text"
                color="#7253CF"
                class="formFields"
                row-height="8"
                :disabled="formLoading"
              >
              </v-textarea>
            </v-row>
            <v-row v-if="this.type == 'edit'">
              <v-col cols="12">
                <v-text-field
                  label="Custom Video URL"
                  outlined
                  dense
                  v-model="video_url"
                  class="formFields"
                  color="#7253CF"
                  :disabled="formLoading"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row v-if="this.type == 'edit'">
              <v-col cols="12">
                <v-text-field
                  label="Facebook URL"
                  outlined
                  dense
                  v-model="facebook_url"
                  class="formFields"
                  color="#7253CF"
                  :disabled="formLoading"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-checkbox
                  color="#7253CF"
                  class="formFields"
                  label="Boosted On Facebook"
                  v-model="boosted_on_fb"
                ></v-checkbox>
              </v-col>
              <v-col cols="12">
                <p style="font-size: 19px">Pretix Redirect URL</p>
              </v-col>
              <br />
              <b style="font-size: 16px; margin-left: 1%; margin-top: 3%"
                >www.harlemwizards.com/tickets/</b
              >
              <v-col cols="8">
                <v-text-field
                  placeholder=""
                  outlined
                  dense
                  v-model="pretix_redirect_url"
                  class="formFields"
                  color="#7253CF"
                  :disabled="formLoading"
                  style="margin-left: -10px"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <div>
                <v-checkbox
                  color="#38227A"
                  v-model="is_completed"
                  label="First Call Completed"
                  @change="updateStatus(is_completed, id), resendInvitation()"
                ></v-checkbox>
              </div>
              &nbsp;&nbsp;
              <v-col cols="3" class="pl-2" style="margin-top: -2px">
                <v-menu
                  ref="menu8"
                  v-model="menu8"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      outlined
                      dense
                      v-model="formattedFirstCallDate"
                      prepend-inner-icon="mdi-calendar-range"
                      label="First Call Completed Date"
                      v-bind="attrs"
                      readonly
                      color="#7253CF"
                      class="formFields"
                      :disabled="formLoading"
                      v-on="on"
                    >
                    </v-text-field>
                  </template>
                  <v-date-picker
                    v-model="completion_date"
                    no-title
                    @input="menu8 = false"
                    @change="updateStatus(is_completed, id)"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            v-if="type == 'add'"
            :loading="formLoading"
            class="rounded-lg dialogAction-btnText px-9"
            height="43px"
            dark
            color="#38227A"
            @click="submitForm"
          >
            <span>Add New Game</span>
          </v-btn>
          <v-btn
            v-else
            :loading="formLoading"
            class="rounded-lg dialogAction-btnText px-9"
            height="43px"
            dark
            color="#38227A"
            @click="
              submitForm();
              updatePreixTime();
            "
          >
            <span>Save</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script src="https://maps.googleapis.com/maps/api/js"></script>
<script>
import VueTimepicker from "vue2-timepicker";
import "vue2-timepicker/dist/VueTimepicker.css";
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import RULES from "@/common/fieldRules";
import Axios from "@/api/BaseAxios";
import "quill/dist/quill.snow.css";
import { quillEditor } from "vue-quill-editor";
import {
  API_ADMIN_GET_SCHOOL_LIST,
  ADMIN_GET_TEAM_LIST,
  API_USER_GET_SALES_REP_LIST,
  ADMIN_TICKET_TYPE_LIST,
  ADMIN_GAMES_POST_API,
  ADMIN_GAMES_GET_DETAIL,
  API_MASTER_GET_STATES_LIST,
  API_ADMIN_TEAM_NAME_LIST,
  GAME_QUOTA_TICKET_INFO_GET_API,
  GAME_QUOTA_TICKET_INFO_PATCH_API,
  GAME_QUOTA_PRICE_INFO_GET_API,
  GAME_QUOTA_PRICE_INFO_PATCH_API,
  API_CHECKLIST_STATUS_UPDATE,
  API_EVENT_TEAM_PLAYER_LIST_GET,
  API_USER_GET_EVENT_SUPPORT_LIST,
  API_SCHOOL_USER_CONNECT_LIST,
} from "@/constants/APIUrls";
import Helper from "@/helper";
export default {
  name: "AddEditGameModal",
  components: { VueTimepicker, quillEditor },
  data() {
    return {
      menu: false,
      menu1: false,
      menu2: false,
      menu3: false,
      menu4: false,
      menu5: false,
      menu6: false,
      menu7: false,
      menu8: false,
      rules: RULES,
      loading: false,
      formLoading: false,
      selectedEventRadio: "",
      eventRadio: [
        { label: "First Event", value: "First Event" },
        { label: "Repeat Event", value: "Repeat Event" },
        { label: "Repeat BUT new Host", value: "Repeat BUT new Host" },
      ],
      school: null,
      schoolList: [],
      gameDate: "",
      gameTime: "",
      start_time: "08:00 AM",
      end_time: "08:00 AM",
      adt: "",
      admission_time: "",
      contract_status: "",
      assignedTeam: null,
      assignedTeamList: [],
      salesRep: null,
      salesRepList: [],
      eventSupportList: [],
      lisssst: [{ id: null, team_name: "unassigned" }],
      demoTeamList: [this.lisssst + this.teamNameList],
      teamNameList: [],
      searchQ: null,
      bestCaseGoal: null,
      worstCaseGoal: null,
      ticketType: [],
      ticketTypeList: [],
      numberOfStudentDistrict: null,
      name: "",
      first_name: "",
      last_name: "",
      mobile: "",
      email: "",
      organization_name: "",
      leadGroup: "",
      shippingAddress: "",
      eventAddress: "",
      contractType: "Low Risk",
      baseFees: null,
      ticketRevenue: null,
      paymentDeposit: null,
      minimumFees: null,
      travel_fees: null,
      Fees: null,
      facebook_url: "",
      video_url: "",
      pretix_redirect_url: "",
      boosted_on_fb: false,
      ticketCopy: [],
      playerCopy: [],
      city: "",
      state: null,
      statesList: [],
      players: [],
      playerList: [],
      team_name: "",
      team_id: "",
      team: "",
      event_support: "",
      title: "",
      pinCode: "",
      contractDueDate: "",
      depositDueDate: "",
      no_of_ticket_if_repeat: null,
      total_gate: null,
      is_wizfit: "",
      event_overview: "",
      fundraising_goal: null,
      go_for_goal: null,
      seating_capacity: null,
      no_k8_students_marketing_to: null,
      no_k8_students_in_district: null,
      no_of_elementary_school: null,
      no_of_middle_school: null,
      ticket_increased_from_last_game: "",
      virtual_assembly: "",
      in_person_assembly: "",
      ticket_incentive_program: "",
      ticket_incentive_detail: "",
      nameOfEventLocation: "",
      previewImage: null,
      logo_obj: {},
      notes: "",
      questions: ["Yes", "No", "Maybe"],
      ticketPrice: [],
      ticketprice: [],
      pretixslug: false,
      create_on_pretix: "",
      ticketquota: [],
      ticketquotafrmat: [],
      pretix_live: "",
      pretix_testmode: "",
      pretix_game_mode: "",
      pretix_tax_percentage: "",
      completion_date: "",

      presale_startdate: "",
      presale_status: "",
      presale_starttime: "08:00 AM",
      game_type: "",
      frontpage_text: "",
      center: {
        lat: "",
        lng: "",
      },
      locationMarkers: [],
      locPlaces: [],
      existingPlace: null,
      timezoneOptions: [
        "US/Central",
        "US/Eastern",
        "US/Mountain",
        "US/Pacific",
      ],
      gameTypeOptions: ["Local", "Road"],
      game_timezone: "",
      firstcallId: "",
      is_completed: "",
      phoneValue: 0,
      formattedPhoneValue: "0",
      preventNextIteration: false,
      enteredEmail: "",
      matchedEmails: [],
      emailList: [],
      hiddenValue1: "",
      hiddenValue2: "",
      presaleStatusOptions: ["Updated", "Completed"],
      co_chair_name: "",
      co_chair_phone: "",
      co_chair_email: "",
    };
  },
  computed: {
    ...mapGetters({
      showGetter: "gameManagement/getShow",
    }),
    show: {
      get() {
        return this.showGetter;
      },
      set(value) {
        return this.toggleAddEditViewGameModal({ show: value });
      },
    },
    type() {
      return this.$store.state.gameManagement.addEditViewModal.type;
    },
    dashboard() {
      return this.$store.state.gameManagement.addEditViewModal.dashboard;
    },
    formatedDate: {
      get() {
        if (this.gameDate) {
          return moment(this.gameDate).format("MM-DD-YYYY");
        } else {
          return "";
        }
      },
      set() {
        return null;
      },
    },
    formattedContractDueDate: {
      get() {
        if (this.contractDueDate) {
          return moment(this.contractDueDate).format("MM-DD-YYYY");
        } else {
          return "";
        }
      },
      set() {
        return "";
      },
    },
    formattedDepositDueDate: {
      get() {
        if (this.depositDueDate) {
          return moment(this.depositDueDate).format("MM-DD-YYYY");
        } else {
          return "";
        }
      },
      set() {
        return "";
      },
    },
    formattedTime: {
      get() {
        if (this.start_time) {
          return Helper.timeFormatter(this.start_time);
        } else {
          return "";
        }
      },
    },
    formattedPresaleTime: {
      get() {
        if (this.presale_starttime) {
          return Helper.timeFormatter(this.presale_starttime);
        } else {
          return "";
        }
      },
    },
    formattedEndTime: {
      get() {
        if (this.end_time) {
          return Helper.timeFormatter(this.end_time);
        } else {
          return "";
        }
      },
    },
    formattedDoorOpenTime: {
      get() {
        if (this.admission_time) {
          return Helper.timeFormatter(this.admission_time);
        } else {
          return "";
        }
      },
    },
    formattedPresaleDate: {
      get() {
        if (this.presale_startdate) {
          return moment(this.presale_startdate).format("MM-DD-YYYY");
        } else {
          return "";
        }
      },
      set() {
        return "";
      },
    },
    formattedFirstCallDate: {
      get() {
        if (this.completion_date) {
          return moment(this.completion_date).format("YYYY-MM-DD");
        } else {
          return "";
        }
      },
      set() {
        return "";
      },
    },
    dialogTitle() {
      switch (this.type) {
        case "add":
          return "Add New Game";
        case "edit":
          return "Edit Game";
        default:
          return "";
      }
    },
  },
  created() {
    this.$root.$refs.AddEditGameModal = this;
  },

  watch: {
    show(value) {
      if (value) {
        this.openModal();
      } else {
        this.closeModal();
      }
    },
    ticketType() {
      console.log(this.ticketType);
      this.ticketCopy = [];
      Object.keys(this.ticketType).forEach((key) =>
        this.ticketCopy.push(this.ticketType[key].id)
      );
      console.log(this.ticketCopy);
    },
  },
  methods: {
    ...mapActions({
      toggleAddEditViewGameModal: "gameManagement/toggleModal",
      showToast: "snackBar/showToast",
    }),
    openModal() {
      this.getSchoolList();
      // this.locateGeoLocation();
    },
    closeModal() {
      //   this.$refs.gameForm.reset();
      this.school = null;
      this.schoolList = [];
      this.gameDate = "";
      this.gameTime = "";
      this.start_time = "";
      this.end_time = "";
      this.admission_time = "";
      this.assignedTeam = null;
      this.assignedTeamList = [];
      this.salesRep = null;
      this.salesRepList = [];
      this.loading = false;
      this.formLoading = false;
      this.bestCaseGoal = null;
      this.worstCaseGoal = null;
      this.ticketType = [];
      this.ticketTypeList = [];
      this.numberOfStudentDistrict = null;
      this.name = "";
      this.mobile = "";
      this.email = "";
      this.leadGroup = "";
      this.shippingAddress = "";
      this.eventAddress = "";
      this.nameOfEventLocation = "";
      this.baseFees = null;
      this.ticketRevenue = null;
      this.paymentDeposit = null;
      this.Fees = null;
      this.contractType = "Low Risk";
      this.ticketCopy = [];
      this.city = "";
      this.state = null;
      this.statesList = [];
      this.event_support = "";
      this.team_name = "";
      this.team = "";
      this.title = "";
      this.pinCode = "";
      this.contractDueDate = "";
      this.depositDueDate = "";
      this.no_of_ticket_if_repeat = null;
      this.total_gate = null;
      this.is_wizfit = "";
      this.event_overview = "";
      this.fundraising_goal = null;
      this.go_for_goal = null;
      this.seating_capacity = null;
      this.no_k8_students_marketing_to = null;
      this.no_k8_students_in_district = null;
      this.no_of_elementary_school = null;
      this.no_of_middle_school = null;
      this.ticket_increased_from_last_game = "";
      this.virtual_assembly = "";
      this.in_person_assembly = "";
      this.ticket_incentive_program = "";
      this.ticket_incentive_detail = "";
      this.notes = "";
      this.previewImage = null;
      this.logo_obj = {};
      this.organization_name = "";
      this.ticketPrice = [];
    },
    onUploadLogoChange(payload) {
      // const file = payload.target.files[0]; // use it in case of normal HTML input
      let file = payload; // in case vuetify file input
      console.log(file, "logoFile");
      console.log(file.size);
      console.log(file.type);
      if (file.size < 1000000) {
        console.log(file.type);
        this.logo_obj = payload;
        if (file) {
          this.previewImage = URL.createObjectURL(file);
          URL.revokeObjectURL(file); // free memory
        } else {
          this.previewImage = null;
        }
      } else {
        console.log("image validation error");
        this.showToast({
          message: "Logo file size must be less than 10mb",
          color: "e",
        });
      }

      // console.log(this.previewImage, "previewImage");
      // console.log(this.logo_obj, "LogoImgFile");
    },
    timeFormatter(time) {
      let timeInt = parseInt(time);
      let minutes = time.substring(3, 5);
      if (time.split(":")[0] == "00") return `12:${time.split(":")[1]} AM`;
      if (time >= "13:00") return `${timeInt - 12}:${minutes} PM`;
      else if (time >= "12:00") return `${timeInt}:${minutes} PM`;
      else return `${timeInt}:${minutes} AM`;
    },

    getSchoolList() {
      this.loading = true;
      const successHandler = (res) => {
        console.log(res.data);
        const data = res.data;
        this.schoolList = data.school_list;
      };
      const failureHandler = (res) => {
        console.log(res);
        this.loading = false;
      };
      const finallyHandler = () => {
        this.getTeamList();
      };
      let formData = {};
      Axios.request_GET(
        API_ADMIN_GET_SCHOOL_LIST,
        formData,
        {},
        successHandler,
        failureHandler,
        false,
        true,
        finallyHandler
      );
    },
    getTeamList() {
      const successHandler = (res) => {
        this.assignedTeamList = res.data.team_list;
      };
      const failureHandler = (res) => {
        console.log(res);
        this.loading = false;
      };
      const finallyHandler = () => {
        this.getSalesRepList();
        this.getEventSupportList();
      };
      let formData = {};
      Axios.request_GET(
        ADMIN_GET_TEAM_LIST,
        formData,
        {},
        successHandler,
        failureHandler,
        false,
        true,
        finallyHandler
      );
    },
    getSalesRepList() {
      const self = this;
      const successHandler = (res) => {
        const data = res.data;
        self.salesRepList = data.sales_rep_list;
      };
      const failureHandler = (res) => {
        console.log(res);
        self.loading = false;
      };
      const finallyHandler = () => {
        this.getStateList();
        this.getteamPlayer();
        this.getTeamNameList();
      };
      const formJson = {};
      return Axios.request_GET(
        API_USER_GET_SALES_REP_LIST,
        formJson,
        {},
        successHandler,
        failureHandler,
        false,
        false,
        finallyHandler
      );
    },
    getEventSupportList() {
      const self = this;
      const successHandler = (res) => {
        const data = res.data;
        self.eventSupportList = data.event_support_staff_list;
      };
      const failureHandler = (res) => {
        console.log(res);
        self.loading = false;
      };
      const finallyHandler = () => {};
      const formJson = {};
      return Axios.request_GET(
        API_USER_GET_EVENT_SUPPORT_LIST,
        formJson,
        {},
        successHandler,
        failureHandler,
        false,
        false,
        finallyHandler
      );
    },
    getTeamNameList() {
      console.log("!!!!!!!!!!!");
      const self = this;
      self.loading = true;
      console.log("!!!!!!!!!!!$$$");
      const successHandler = (res) => {
        const teamnaeee = res.data.team_name_list;
        const teamlistsss = [{ id: null, team_name: "Custom" }];
        self.teamNameList = teamlistsss.concat(teamnaeee);
        console.log("self.teamNameList", self.teamNameList);
      };
      const failureHandler = (res) => {
        console.log(res);
        self.loading = false;
      };
      const finallyHandler = () => {};
      const formData = {};
      return Axios.request_GET(
        API_ADMIN_TEAM_NAME_LIST,
        formData,
        {},
        successHandler,
        failureHandler,
        false,
        false,
        finallyHandler
      );
    },
    updateStatus(status, id) {
      const successHandler = (res) => {
        console.log(res);
      };
      const failureHandler = (res) => {
        console.log(res);
      };
      const finallyHandler = () => {
        // this.getGameDetail();
      };
      let formData = {};
      formData["game"] =
        this.$store.state.gameManagement.addEditViewModal.gameID;
      formData["id"] = this.firstcallId;
      formData["is_completed"] = status;
      if (this.formattedFirstCallDate) {
        formData["completion_date"] = this.formattedFirstCallDate;
      } else {
        formData["completion_date"] = this.currentDate();
      }
      Axios.request_PATCH(
        API_CHECKLIST_STATUS_UPDATE,
        formData,
        {},
        successHandler,
        failureHandler,
        false,
        true,
        finallyHandler
      );
    },
    currentDate() {
      const current = new Date();
      const date = `${current.getFullYear()}-0${
        current.getMonth() + 1
      }-${current.getDate()}`;
      return date;
    },
    getStateList() {
      const self = this;

      const successHandler = function (res) {
        const data = res.data;
        console.log(data.state_list);
        self.statesList = data.state_list;
      };

      const failureHandler = function (res) {
        const data = res.data;
        console.log(data);
      };

      const finallyHandler = function () {
        self.getTicketType();
      };

      const formJson = {};

      return Axios.request_GET(
        API_MASTER_GET_STATES_LIST,
        formJson,
        {},
        successHandler,
        failureHandler,
        false,
        false,
        finallyHandler
      );
    },
    getteamPlayer() {
      const successHandler = (res) => {
        this.playerList = res.data.player_list;
        //this.setplayers(res.data.player_list);
      };
      const failureHandler = (res) => {
        console.log(res);
        this.loading = false;
      };
      const finallyHandler = () => {};
      let formData = {};
      if (this.team_name) {
        formData["team_name"] = this.team_name;
      }
      Axios.request_GET(
        API_EVENT_TEAM_PLAYER_LIST_GET,
        formData,
        {},
        successHandler,
        failureHandler,
        false,
        true,
        finallyHandler
      );
    },
    getTicketType() {
      const self = this;
      const successHandler = (res) => {
        this.ticketTypeList = res.data.ticket_list;
      };
      const failureHandler = (res) => {
        console.log(res);
        self.loading = false;
      };
      const finallyHandler = () => {
        if (this.type === "edit") {
          this.getGameDetail();
          this.getTicketQuotaDetail();
          this.getPriceQuotaDetail();
        } else {
          this.loading = false;
        }
      };
      let formData = {};
      Axios.request_GET(
        ADMIN_TICKET_TYPE_LIST,
        formData,
        {},
        successHandler,
        failureHandler,
        false,
        true,
        finallyHandler
      );
    },
    getGameDetail() {
      const successHandler = (res) => {
        this.assignData(res.data.game_detail);
        this.pretixslug = res.data.game_detail.pretix_slug;
        if (res.data.game_detail.pretix_live == true) {
          this.pretix_game_mode = "live_mode";
        }
        if (res.data.game_detail.pretix_testmode == true) {
          this.pretix_game_mode = "test_mode";
        }
        this.pretix_tax_percentage = res.data.game_detail.pretix_tax_percentage;
        this.contract_status = res.data.game_detail.contract_status;
        this.presale_startdate = res.data.game_detail.presale_startdate;
        this.presale_status = res.data.game_detail.presale_status;
        this.frontpage_text = res.data.game_detail.frontpage_text;
        this.game_type = res.data.game_detail.game_type;
        if (res.data.game_detail.checklist_status.length == 5) {
          this.completion_date =
            res.data.game_detail.checklist_status[1].completion_date;
          this.firstcallId = res.data.game_detail.checklist_status[1].id;
        } else {
          this.completion_date =
            res.data.game_detail.checklist_status[0].completion_date;
          this.firstcallId = res.data.game_detail.checklist_status[0].id;
        }
        this.mobile = res.data.game_detail.host_phone;
        //  .replace(/[^0-9]/g, "")
        //   .replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
        this.is_completed =
          res.data.game_detail.checklist_status[0].is_completed;
        console.log("&&&&&&&&&&&", res.data.game_detail.player_list);
        if (res.data.game_detail.player_list != null) {
          let playerIdList = res.data.game_detail.player_list.map(function (
            obj
          ) {
            return obj.id;
          });
          this.players = this.playerList.filter((k) =>
            playerIdList.includes(k.id)
          );
        }
      };
      const failureHandler = (res) => {
        console.log(res);
        this.loading = false;
      };
      let formData = {};
      formData["game_id"] =
        this.$store.state.gameManagement.addEditViewModal.gameID;
      Axios.request_GET(
        ADMIN_GAMES_GET_DETAIL,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    setplayers(playerList) {
      const self = this;
      if (this.players[0]) {
        let res = [];
        res = playerList.filter((el) => {
          return !self.filterList.find((element) => {
            return element.id === el.id;
          });
        });
        self.players = self.players.concat(res);
      } else {
        this.players = playerList;
        this.filterList = playerList;
      }
    },
    assignData(itemDetail) {
      console.log(itemDetail, "item detail");
      this.previewImage = itemDetail.logo;
      this.contractType = itemDetail.contract_type;
      this.Fees = itemDetail.fees;
      this.travel_fees = itemDetail.travel_fees;
      this.facebook_url = itemDetail.facebook_url;
      this.video_url = itemDetail.video_url;
      this.boosted_on_fb = itemDetail.boosted_on_fb;
      this.pretix_redirect_url = itemDetail.pretix_redirect_url;
      this.ticketRevenue = itemDetail.ticket_revenue;
      this.paymentDeposit = itemDetail.payment_deposit;
      (this.name = itemDetail.host_name.split(" ")),
        (this.first_name = this.name[0]);
      this.last_name = this.name[this.name.length - 1];
      this.mobile = itemDetail.host_phone;
      this.email = itemDetail.host_email;
      this.co_chair_name = itemDetail.co_chair_name;
      this.co_chair_phone = itemDetail.co_chair_phone;
      this.co_chair_email = itemDetail.co_chair_email;
      this.organization_name = itemDetail.organization;
      this.title = itemDetail.title;
      this.gameDate = itemDetail.date;
      //  this.players = itemDetail.players;
      let playersIdList = itemDetail.players.map(function (obj) {
        return obj.id;
      });
      this.players = itemDetail.players.filter((k) =>
        playersIdList.includes(k.id)
      );

      /** if (itemDetail.start_time) {
        let timeForm = itemDetail.start_time.split(":");
        this.start_time = `${timeForm[0]}:${timeForm[1]}`;
      } **/

      this.start_time = Helper.convertFrom24To12Format(itemDetail.start_time);
      this.end_time = Helper.convertFrom24To12Format(itemDetail.end_time);
      if (itemDetail.admission_time) {
        this.admission_time = Helper.convertFrom24To12Format(
          itemDetail.admission_time
        );
      }
      if (itemDetail.presale_starttime) {
        this.presale_starttime = Helper.convertFrom24To12Format(
          itemDetail.presale_starttime
        );
      }
      this.game_timezone = itemDetail.game_timezone;
      this.salesRep = itemDetail.sales_rep;
      this.nameOfEventLocation = itemDetail.event_location_name;
      this.eventAddress = itemDetail.event_location;
      this.state = itemDetail.state;
      this.city = itemDetail.city;
      this.pinCode = itemDetail.zip_code;
      this.contractDueDate = itemDetail.contract_due;
      this.depositDueDate = itemDetail.deposit_due;
      this.ticketType = itemDetail.ticket_type_list;
      this.ticketPrice = itemDetail.ticket_type_list;
      this.selectedEventRadio = itemDetail.event_type;
      this.no_of_ticket_if_repeat = itemDetail.ticket_sold;
      this.total_gate = itemDetail.total_gate;
      this.is_wizfit = itemDetail.is_wizfit;
      this.event_overview = itemDetail.overview;
      this.fundraising_goal = itemDetail.fundraising_goal;
      this.go_for_goal = itemDetail.go_for_goal;
      this.seating_capacity = itemDetail.total_seat;
      this.no_k8_students_marketing_to = itemDetail.total_marketing_student;
      this.no_k8_students_in_district = itemDetail.total_district_student;
      this.no_of_elementary_school = itemDetail.total_elementary_school;
      this.no_of_middle_school = itemDetail.total_middle_school;
      this.ticket_increased_from_last_game = itemDetail.increase_in_ticket;
      this.virtual_assembly = itemDetail.virtual_assemby;
      this.in_person_assembly = itemDetail.person_assemby;
      this.ticket_incentive_program = itemDetail.ticket_incentive_program;
      this.ticket_incentive_detail = itemDetail.ticket_incentive_detail;
      if (itemDetail.notes) {
        this.notes = itemDetail.notes;
      }
      this.team_name = itemDetail.team_name;
      this.team = itemDetail.team;
      this.event_support = itemDetail.event_support;
      if (itemDetail.pretix_slug != null) {
        this.create_on_pretix = true;
      } else {
        this.create_on_pretix = false;
      }
      this.pretix_tax_percentage = itemDetail.pretix_tax_percentage;
      this.presale_startdate = itemDetail.presale_startdate;
      this.presale_status = itemDetail.presale_status;
      this.completion_date = itemDetail.completion_date;
      //this.pretix_live = itemDetail.pretix_live;
      //this.pretix_testmode = itemDetail.pretix_testmode;
      // this.gameTime = itemDetail
      // this.school = itemDetail.school;
      // this.gameDate = itemDetail.date;
      // this.assignedTeam = itemDetail.team_assigned;
      // this.salesRep = itemDetail.sales_rep;
      // this.bestCaseGoal = itemDetail.best_case_goal;
      // this.worstCaseGoal = itemDetail.worst_case_goal;
      // this.ticketType = itemDetail.ticket_type_list;
      // this.numberOfStudentDistrict = itemDetail.total_student;
      // this.name = itemDetail.name;
      // this.mobile = itemDetail.phone;
      // this.email = itemDetail.email;
      // this.leadGroup = itemDetail.lead_group;
      // this.shippingAddress = itemDetail.shipping_address;
      // this.eventAddress = itemDetail.event_address;
      // this.contractType = itemDetail.contract_type;
      // this.Fees = itemDetail.fees;
      // this.ticketRevenue = itemDetail.ticket_revenue;
      // this.paymentDeposit = itemDetail.payment_deposit;
      // this.city = itemDetail.city;
      // this.state = itemDetail.state;
      // this.event_support = itemDetail.event_support;
      // // console.log(itemDetail.time,"time")
      // let timeForm = itemDetail.time.split(":");
      // // console.log(`${timeForm[0]}:${timeForm[1]}`)
      // this.gameTime = `${timeForm[0]}:${timeForm[1]}`;
      this.loading = false;
    },

    selectEmail(email, additionalValue1, additionalValue2, event) {
      // Prevent the default button click behavior (form submission)
      event.preventDefault();

      // Set the selected email in the input
      this.email = email;
      this.hiddenValue1 = additionalValue1;
      this.hiddenValue2 = additionalValue2;
      // Clear the search results
      this.emailList = [];
    },
    autocalculateendtime() {
      // alert("this.start_time")
      const startTime = this.start_time;
      var endTime = "";
      const durationInMinutes = "120";
      if (startTime != "") {
        if (startTime.includes("PM")) {
          endTime =
            moment(startTime, "HH:mm")
              .add(durationInMinutes, "minutes")
              .format("HH:mm") + " PM";
        } else if (startTime.includes("AM")) {
          endTime = moment(startTime, "HH:mm")
            .add(durationInMinutes, "minutes")
            .format("HH:mm A");
        }
      } else {
        endTime = "";
      }
      this.end_time = endTime;
    },
    autocalculatdoorsopenattime() {
      const startTime = this.start_time;
      var admission_time = "";
      const durationInMinutes = "60";
      if (startTime != "") {
        if (startTime.includes("PM")) {
          admission_time =
            moment(startTime, "HH:mm")
              .subtract(durationInMinutes, "minutes")
              .format("HH:mm") + " PM";
        } else if (startTime.includes("AM")) {
          admission_time = moment(startTime, "HH:mm")
            .subtract(durationInMinutes, "minutes")
            .format("HH:mm A");
        }
      } else {
        admission_time = "";
      }
      this.admission_time = admission_time;
      this.autocalculateendtime();
    },
    submitForm() {
      if (this.$refs.gameForm.validate()) {
        this.formLoading = true;
        const successHandler = (res) => {
          console.log(res);
          this.formLoading = false;
          this.showToast({
            message: "Submitted Successfully ",
            color: "s",
          });
          this.toggleAddEditViewGameModal({ show: false });
          // this.$emit("reload");
          //location.reload();
        };
        const failureHandler = (res) => {
          console.log(res);
          this.formLoading = false;
        };
        let formData = {};
        if (this.logo_obj instanceof File) {
          formData["logo"] = this.logo_obj;
        }
        if (this.contractType) {
          formData["contract_type"] = this.contractType;
        }
        if (this.game_timezone) {
          formData["game_timezone"] = this.game_timezone;
        }
        if (this.Fees) {
          formData["fees"] = this.Fees;
        }
        if (this.paymentDeposit) {
          formData["payment_deposit"] = this.paymentDeposit;
        }
        if (this.ticketRevenue) {
          formData["ticket_revenue"] = this.ticketRevenue;
        }
        if (this.travel_fees) {
          formData["travel_fees"] = this.travel_fees;
        }
        if (this.video_url) {
          formData["video_url"] = this.video_url;
        }
        if (this.facebook_url) {
          formData["facebook_url"] = this.facebook_url;
        }
        formData["boosted_on_fb"] = this.boosted_on_fb;
        if (this.pretix_redirect_url) {
          formData["pretix_redirect_url"] = this.pretix_redirect_url;
        }
        if (this.first_name) {
          formData["first_name"] = this.first_name;
        }
        if (this.last_name) {
          formData["last_name"] = this.last_name;
        }
        if (this.mobile) {
          // formData["phone"] = this.mobile;
          //  alert(this.mobile)
          formData["phone"] = this.mobile.replace(/[^\d\+]/g, "");
          // alert(this.mobile.replace(/[^\d\+]/g, ""))
        }
        if (this.email) {
          formData["email"] = this.email;
        }
        if (this.hiddenValue1) {
          formData["user_type"] = this.hiddenValue1;
        }
        if (this.hiddenValue2) {
          formData["sub_user_type"] = this.hiddenValue2;
        }
        if (this.organization_name) {
          formData["organization"] = this.organization_name;
        }
        if (this.title) {
          formData["title"] = this.title;
        }
        if (this.formatedDate) {
          formData["date"] = this.formatedDate;
        }
        if (this.start_time) {
          formData["start_time"] = Helper.convertTime12To24(this.start_time);
        }
        if (this.end_time) {
          formData["end_time"] = Helper.convertTime12To24(this.end_time);
        }
        if (this.admission_time) {
          formData["admission_time"] = Helper.convertTime12To24(
            this.admission_time
          );
        }
        if (this.salesRep) {
          formData["sales_rep"] = this.salesRep;
        }
        if (this.nameOfEventLocation) {
          formData["event_location_name"] = this.nameOfEventLocation;
        }
        if (this.eventAddress) {
          formData["event_location"] = this.eventAddress;
        }
        if (this.state) {
          formData["state"] = this.state;
        }
        if (this.city) {
          formData["city"] = this.city;
        }
        if (this.pinCode) {
          formData["zip_code"] = this.pinCode;
        }
        if (this.contract_status) {
          formData["contract_status"] = this.contract_status;
        }
          if (this.co_chair_name) {
        formData["co_chair_name"] = this.co_chair_name;
        }
          if (this.co_chair_phone) {
        formData["co_chair_phone"] = this.co_chair_phone.replace(
          /[^\d\+]/g,
          ""
        );
        }
         if (this.co_chair_email) {
        formData["co_chair_email"] = this.co_chair_email;
}
        if (this.formattedContractDueDate) {
          formData["contract_due"] = this.formattedContractDueDate;
        }
        if (this.formattedDepositDueDate) {
          formData["deposit_due"] = this.formattedDepositDueDate;
        }
        if (this.ticketType) {
          formData["ticket_type"] = JSON.stringify(this.ticketType);
        }
        this.playerCopy = [];
        Object.keys(this.players).forEach((key) =>
          this.playerCopy.push(this.players[key].id)
        );
        if (this.team) {
          formData["team"] = this.team;
          formData["players"] = [];
        } else {
          formData["team"] = null;
          formData["players"] = this.playerCopy;
        }
        if (this.event_support) {
          formData["event_support"] = this.event_support;
        }
        if (this.type == "edit") {
          if (this.selectedEventRadio) {
            formData["event_type"] = this.selectedEventRadio;
          }
          if (this.no_of_ticket_if_repeat) {
            formData["ticket_sold"] = this.no_of_ticket_if_repeat;
          }
          if (this.total_gate) {
            formData["total_gate"] = this.total_gate;
          }
          if (this.is_wizfit) {
            formData["is_wizfit"] = this.is_wizfit;
          }
          if (this.event_overview) {
            formData["overview"] = this.event_overview;
          }
          if (this.fundraising_goal) {
            formData["fundraising_goal"] = this.fundraising_goal;
          }
          if (this.go_for_goal) {
            formData["go_for_goal"] = this.go_for_goal;
          }
          if (this.seating_capacity) {
            formData["total_seat"] = this.seating_capacity;
          }
          if (this.no_k8_students_marketing_to) {
            formData["total_marketing_student"] =
              this.no_k8_students_marketing_to;
          }
          if (this.no_k8_students_in_district) {
            formData["total_district_student"] =
              this.no_k8_students_in_district;
          }
          if (this.no_of_elementary_school) {
            formData["total_elementary_school"] = this.no_of_elementary_school;
          }
          if (this.no_of_middle_school) {
            formData["total_middle_school"] = this.no_of_middle_school;
          }
          // ticket price

          // ticket price
          if (this.ticket_increased_from_last_game) {
            formData["increase_in_ticket"] =
              this.ticket_increased_from_last_game;
          }
          if (this.virtual_assembly) {
            formData["virtual_assemby"] = this.virtual_assembly;
          }
          if (this.in_person_assembly) {
            formData["person_assemby"] = this.in_person_assembly;
          }
          if (this.ticket_incentive_program) {
            formData["ticket_incentive_program"] =
              this.ticket_incentive_program;
          }
          if (this.ticket_incentive_detail) {
            formData["ticket_incentive_detail"] = this.ticket_incentive_detail;
          }

          formData["notes"] = this.notes;
          // if (this.ticketPrice.length) {
          //   formData["ticket_price"] = JSON.stringify(this.ticketPrice);
          // }
        }

        if (this.type === "add") {
          Axios.request_POST(
            ADMIN_GAMES_POST_API,
            formData,
            {},
            successHandler,
            failureHandler,
            false
          );
        } else {
          formData["id"] =
            this.$store.state.gameManagement.addEditViewModal.gameID;
          formData["create_on_pretix"] = this.create_on_pretix;
          formData["pretix_tax_percentage"] = this.pretix_tax_percentage;
          if (this.type == "edit") {
            this.submitTicketForm();
            this.submitPriceForm();
            if (this.formattedPresaleDate) {
              formData["presale_startdate"] = this.formattedPresaleDate;
            }
            if (this.presale_starttime) {
              formData["presale_starttime"] = Helper.convertTime12To24(
                this.presale_starttime
              );
            }
            formData["presale_status"] = this.presale_status;
            // formData["pretix_live"] = this.pretix_live;
            // formData["pretix_testmode"] = this.pretix_testmode;
            formData["pretix_game_mode"] = this.pretix_game_mode;
            // formData["create_on_pretix"] = this.create_on_pretix;
            formData["frontpage_text"] = this.frontpage_text;
            formData["game_type"] = this.game_type;
          }
          // formData["id"] =
          //   this.$store.state.gameManagement.addEditViewModal.gameID;
          Axios.request_PATCH(
            ADMIN_GAMES_POST_API,
            formData,
            {},
            successHandler,
            failureHandler,
            false
          );
        }
      }
    },

    getTicketQuotaDetail() {
      const successHandler = (res) => {
        console.log(res.data);
        this.ticketquota = res.data.game_quotas;
        console.log("ticketquota", this.ticketquota);
      };
      const failureHandler = (res) => {
        console.log(res);
        this.loading = false;
      };
      let formData = {};
      formData["game_id"] =
        this.$store.state.gameManagement.addEditViewModal.gameID;
      Axios.request_GET(
        GAME_QUOTA_TICKET_INFO_GET_API,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    submitTicketForm() {
      const successHandler = (res) => {
        console.log(res);
        this.formLoading = false;
      };
      const failureHandler = (res) => {
        console.log(res);
        this.formLoading = false;
      };
      let formData = {};
      if (this.ticketquota.length) {
        formData["ticketquota"] = this.ticketquota;
      }
      Axios.request_PATCH(
        GAME_QUOTA_TICKET_INFO_PATCH_API,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    getPriceQuotaDetail() {
      const successHandler = (res) => {
        console.log(res.data);
        this.ticketprice = res.data.gamequota_items;
        console.log("ticketprice", this.ticketprice);
      };
      const failureHandler = (res) => {
        console.log(res);
        this.loading = false;
      };
      let formData = {};
      formData["game_id"] =
        this.$store.state.gameManagement.addEditViewModal.gameID;
      Axios.request_GET(
        GAME_QUOTA_PRICE_INFO_GET_API,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    submitPriceForm() {
      const successHandler = (res) => {
        console.log(res);
        this.formLoading = false;
      };
      const failureHandler = (res) => {
        console.log(res);
        this.formLoading = false;
      };
      let formData = {};
      if (this.ticketprice.length) {
        formData["ticketprice"] = this.ticketprice;
      }
      Axios.request_PATCH(
        GAME_QUOTA_PRICE_INFO_PATCH_API,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    focusOut() {
      if (["Arrow", "Backspace", "Shift"].includes(event.key)) {
        this.preventNextIteration = true;
        return;
      }
      if (this.preventNextIteration) {
        this.preventNextIteration = false;
        return;
      }
      this.phoneValue = this.mobile.replace(/-/g, "").match(/(\d{1,10})/g)[0];

      // Format display value based on calculated currencyValue
      this.mobile = this.phoneValue
        .replace(/[^0-9]/g, "")
        .replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
    },
    initMarker(loc) {
      this.existingPlace = loc;
    },
    setPlace(place) {
      this.currentPlace = place;
    },
    addLocationMarker() {
      if (this.existingPlace) {
        const marker = {
          lat: this.existingPlace.geometry.location.lat(),
          lng: this.existingPlace.geometry.location.lng(),
        };
        this.locationMarkers.push({ position: marker });
        this.locPlaces.push(this.existingPlace);
        this.center = marker;
        this.existingPlace = null;
      }
    },
    locateGeoLocation: function () {
      navigator.geolocation.getCurrentPosition((res) => {
        this.center = {
          lat: res.coords.latitude,
          lng: res.coords.longitude,
        };
      });
    },
    getSchoolEmailList() {
      const self = this;

      const successHandler = function (res) {
        const data = res.data;
        // console.log(data.state_list);
        self.emailList = data.user;
      };

      const failureHandler = function (res) {
        const data = res.data;
        console.log(data);
      };

      const finallyHandler = function () {};

      const formJson = {};
      formJson.search = this.email;
      return Axios.request_GET(
        API_SCHOOL_USER_CONNECT_LIST,
        formJson,
        {},
        successHandler,
        failureHandler,
        false,
        false,
        finallyHandler
      );
    },
    resendInvitation() {
      const successHandler = () => {
        this.showToast({
          message: "Invitation sent",
          color: "s",
        });
      };

      const failureHandler = () => {};
      let formData = {};
      formData["id"] = this.$store.state.gameManagement.addEditViewModal.gameID;
      Axios.request_GET(
        "/game/resend_email/",
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    updatePreixTime() {
      this.getGameDetail();
      this.submitForm();
      setTimeout(() => {
        this.submitTicketForm();
        this.submitPriceForm();
        location.reload();
      }, 5000);
    },
  },
};
</script>
<style scoped>
.dialogTitle-text {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 315%;
  color: #ffffff;
}
.formFields {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;
  /* identical to box height */

  color: #757575;
}
.dialogAction-btnText >>> span {
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 161.5%;
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: none !important;
}
.user-initials-text {
  font-family: Lato;
  font-style: normal;
  font-weight: 800;
  font-size: 17px;
  line-height: 315%;
  color: white;
  letter-spacing: 0px;
}
.logo-preview {
  height: 100px;
  width: 200px;
  position: relative;
  bottom: 30px;
}
.logo-des {
  font-size: 12px;
  margin-left: 13px;
  margin-top: 5px;
  font-style: italic;
  color: #404040;
  line-height: 17px;
}

img.logo {
  margin-left: 20px;
  max-height: 100px;
  max-width: 200px;
}
</style>
